import React from "react";
function services(props: any) {
  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__main">
          <div className="container container_secondary">
            <div className="section__title">
              <h3 className="section__text">
                Игра выходит
                <br /> на новый уровень
              </h3>
            </div>
          </div>
        </div>
        <div className="section__main">
          <div className="container container_secondary">
            <div className="layout__services services">
              <div className="services__list">
                <div className="services__item">
                  <picture className="services__media">
                    <svg className="services__ico">
                      <use xlinkHref="/img/sprite.svg#services__ico"></use>
                    </svg>
                  </picture>
                  <div className="services__main">
                    <h6 className="services__title">Оффлайн режим</h6>
                    <span className="services__text">
                      При заказе буста, вы можете включить услугу "Играть оффлайн". Это сделает ваш аккаунт скрытым от друзей, когда на него зайдёт бустер.
                    </span>
                  </div>
                  <div className="services__footer">
                    <a className="button" href="/boost">
                      <span className="button__text">Заказать</span>
                      <svg className="button__ico">
                        <use xlinkHref="/img/sprite.svg#arrowRight"></use>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="services__item">
                  <picture className="services__media">
                    <svg className="services__ico">
                      <use xlinkHref="/img/sprite.svg#services__ico"></use>
                    </svg>
                  </picture>
                  <div className="services__main">
                    <h6 className="services__title">Поддержка во всех соц. сетях 24/7</h6>
                    <span className="services__text">
                      Вы можете запросить помощь по заказу прямо в чате заказа, либо обратившись в любую из соц. сетей.
                    </span>
                  </div>
                  <div className="services__footer">
                  <a className="button" href="/dm">
                    <span className="button__text">Перейти в чат</span>
                      <svg className="button__ico">
                        <use xlinkHref="/img/sprite.svg#arrowRight"></use>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="services__item">
                  <picture className="services__media">
                    <svg className="services__ico">
                      <use xlinkHref="/img/sprite.svg#services__ico"></use>
                    </svg>
                  </picture>
                  <div className="services__main">
                    <h6 className="services__title">Легит бустеры 100%</h6>
                    <span className="services__text">
                      Пул бустеров формируется по инвайт системе, если один из бустеров будет уличен в недобросовестном использовании системы заказов, бан получают все бустеры которых он пригласил.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default services;
