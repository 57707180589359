import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, User } from "../global/AuthContext";

type TelegramOAuthToken = {
  id: string; // User's unique Telegram ID
  first_name?: string; // Optional: User's first name
  last_name?: string; // Optional: User's last name
  username?: string; // Optional: User's username
  photo_url?: string; // Optional: User's profile photo URL
  auth_date: string; // The timestamp of when the user authorized
  hash: string; // The authentication hash for verifying the payload
};


function decodeTelegramOAuthToken(encodedString: string): TelegramOAuthToken {
  // Decode the Base64 string
  const decodedString = atob(encodedString);
  
  // Parse the JSON into an object
  const parsedData = JSON.parse(decodedString) as TelegramOAuthToken;

  // Validate required fields
  if (!parsedData.id || !parsedData.auth_date || !parsedData.hash) {
    throw new Error("Invalid Telegram OAuth data: Missing required fields");
  }

  return parsedData;
}

const HandleRedirect = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    // Detect the hash in the URL
    const hash = window.location.hash;

    if (hash) {
      // Parse the hash to get the parameters
      const params = new URLSearchParams(hash.slice(1)); // Remove the `#`
      const tgAuthResult = params.get("tgAuthResult");

      if (tgAuthResult) {
        console.log("Received tgAuthResult:", tgAuthResult);

        // Trigger the desired action
        handleAuthResult(tgAuthResult);

        // Optional: Clear the hash from the URL
        window.history.replaceState(null, "", window.location.pathname);

        // Redirect to a specific page if needed
        navigate("/"); // Replace with your target route
      }
    }
  }, [navigate]);

  // Your action logic
  const handleAuthResult = (authResult: string) => {
    // todo: parse telegram token json and put it into 
    const telegram_parsed_token = decodeTelegramOAuthToken(authResult);

    const userData = {
      name: telegram_parsed_token.username ?? `${telegram_parsed_token.first_name} ${telegram_parsed_token.last_name}`,
      token: "123abc",
      avatar_url: telegram_parsed_token.photo_url ?? "https://example.com/default-avatar.png" 
    }; // Example user data
    login(userData);

    // TODO: retreive auth token from FastAPI
    // fetch("/api/validate-auth", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ tgAuthResult: authResult }),
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       console.log("Auth result validated!");
    //     } else {
    //       console.error("Failed to validate auth result");
    //     }
    //   })
    //   .catch((error) => console.error("Error:", error));

    debugger;
  };

  return null;
};

export default HandleRedirect;
