'use client'

import React, { useState, useEffect } from "react";

const mockOrders: Order[] = [
  { id: "1222", title: "Order #1222", text: "Lorem ipsum dolor sit amet", time: "Now", value: 3 },
  { id: "1223", title: "Order #1223", text: "Consectetur adipiscing elit", time: "1h ago", value: 2 },
  { id: "1224", title: "Order #1224", text: "Sed do eiusmod tempor", time: "2h ago", value: 1 },
];

const mockChats: Record<string, Chat> = {
  "1222": {
    id: "1222",
    messages: [
      { text: "eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat", isPrimary: true },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
      { text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.", isPrimary: false },
    ],
  },
  "1223": {
    id: "1223",
    messages: [
      { text: "Duis aute irure dolor in reprehenderit", isPrimary: true },
      { text: "Voluptate velit esse cillum dolore eu fugiat nulla pariatur", isPrimary: false },
    ],
  },
  "1224": {
    id: "1224",
    messages: [
      { text: "Excepteur sint occaecat cupidatat non proident", isPrimary: true },
      { text: "Sunt in culpa qui officia deserunt mollit anim id est laborum", isPrimary: false },
    ],
  },
};

export const fetchOrders = (): Promise<Order[]> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(mockOrders), 500);
  });
};

export const fetchChats = (): Promise<Record<string, Chat>> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(mockChats), 500);
  });
};


interface Order {
  id: string;
  title: string;
  text: string;
  time: string;
  value: number;
}

interface Chat {
  id: string;
  messages: { text: string; isPrimary: boolean }[];
}

function Orders() {
  const [activeOrder, setActiveOrder] = useState<string | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [chats, setChats] = useState<Record<string, Chat>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ordersData, chatsData] = await Promise.all([fetchOrders(), fetchChats()]);
        setOrders(ordersData);
        setChats(chatsData);
        if (ordersData.length > 0) {
          setActiveOrder(ordersData[0].id);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOrderClick = (orderId: string) => {
    setActiveOrder(orderId);
  };

  const handleSupportClick = () => {
    alert("Support has been called!");
    // Add your logic here to actually call support
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__main">
          <div className="container">
            <div className="layout__orders orders">
              <aside className="orders__sidebar sidebar">
                <div className="sidebar__main">
                  <div className="orders__list">
                    {orders.map((order) => (
                      <div 
                        key={order.id}
                        className={`orders__item order ${activeOrder === order.id ? 'order__item_active' : ''}`}
                        onClick={() => handleOrderClick(order.id)}
                      >
                        <picture className="order__picture">
                          <img src="/img/image.jpg" alt="image description" />
                        </picture>
                        <div className="order__main">
                          <span className="order__title">{order.title}</span>
                          <span className="order__text">{order.text}</span>
                        </div>
                        <div className="order__footer">
                          <span className="order__time">{order.time}</span>
                          <span className="order__value">{order.value}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </aside>
              <div className="orders__chat chat">
                <header className="chat__header">
                  <div className="orders__account account">
                    <picture className="account__picture">
                      <img src="/img/image.jpg" alt="image description" />
                    </picture>
                    <span className="account__text">Order #{activeOrder}</span>
                  </div>
                  <div className="orders__support support" onClick={handleSupportClick}>
                    <svg className="support__ico">
                      <use xlinkHref="/img/sprite.svg#support"></use>
                    </svg>
                    <span className="support__text">Позвать саппорт</span>
                  </div>
                </header>
                <main className="chat__main">
                  <div className="chat__list">
                    {activeOrder && chats[activeOrder]?.messages.map((message, index) => (
                      <div key={index} className={`chat__item chat__item_${message.isPrimary ? 'primary' : 'secondary'}`}>
                        <picture className="chat__picture">
                          <img src="/img/image.jpg" alt="image description" />
                        </picture>
                        <span className="chat__text">{message.text}</span>
                      </div>
                    ))}
                  </div>
                </main>
                <footer className="chat__footer">
                  <div className="form">
                    <div className="form__item">
                      <label className="form__label">
                        <input
                          className="form__input"
                          type="text"
                          placeholder="Написать сообщение.."
                        />
                        <button className="form__button" type="submit">
                          <svg className="form__ico">
                            <use xlinkHref="/img/sprite.svg#send"></use>
                          </svg>
                        </button>
                      </label>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Orders;

