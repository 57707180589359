import React from "react";
import Boost from "../components/sections/boost/Boost";
function Boosting() {
  return (
    <React.Fragment>
      <div className="layout__main layout__main_boost">
        <Boost />
      </div>
    </React.Fragment>
  );
}
export default Boosting;
