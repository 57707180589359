import React, { useState, useRef } from 'react'
import RankSelector from './RankSelector'

const rankOrder = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Ascendant', 'Immortal', 'Radiant'];

type State = {
  estimatedHours: number;
  currentRank: string;
  desiredRank: string;
  selectedServer: string;
  selectedPlatform: string;
  settings: {
    [key: string]: boolean;
  };
  price: number;
  boostPrice: number;
};

function getRankValue(rank: string): number {
  const [rankName, division, rr] = rank.split(' ');
  const rankIndex = rankOrder.indexOf(rankName);
  const divisionIndex = division ? ['I', 'II', 'III'].indexOf(division) : 0;
  const rrValue = rr ? parseInt(rr.split('-')[0]) : 0;

  return (rankIndex * 1000000) + (divisionIndex * 10000) + rrValue;
}

// Updated sendDataToServer function
async function sendDataToServer(data: any, signal: AbortSignal) {
  await new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve({
        success: true,
        estimatedPrice: 90,
        estimatedHours: 90,
        boostPrice: 60
      });
    }, 500);

    signal.addEventListener('abort', () => {
      clearTimeout(timeout);
      reject(new Error('Request aborted'));
    });
  });
  
  const currentRankIndex = rankOrder.findIndex(rank => data.currentRank.startsWith(rank));
  const desiredRankIndex = rankOrder.findIndex(rank => data.desiredRank.startsWith(rank));
  const rankDifference = desiredRankIndex - currentRankIndex;

  return {
    success: true,
    estimatedPrice: 90,
    estimatedHours: 90,
    boostPrice: 60
  };
}

export default function GameBoostWithServer() {
  const [state, setState] = useState<State>({
    estimatedHours: 1,
    currentRank: 'Bronze I 0-18',
    desiredRank: 'Bronze I 19-36',
    selectedServer: 'Europe',
    selectedPlatform: 'PC',
    settings: {
      "Играть оффлайн": false,
      "Предпочтения по агентам": false,
      "Стриминг": false,
      "Экспресс доставка": false,
      "Играть с бустером": false
    },
    price: 90,
    boostPrice: 90
  });

  const abortControllerRef = useRef<AbortController | null>(null);

  const platforms = [
    { name: 'PC', icon: 'boost__ico01', disabled: false },
    // { name: 'Xbox', icon: 'boost__ico02', disabled: true },
    // { name: 'PlayStation', icon: 'boost__ico03', disabled: true }
  ];

  const settingPrices = {
    "Играть оффлайн": "FREE",
    "Предпочтения по агентам": "FREE",
    "Стриминг": "+10%",
    "Экспресс доставка": "+20%",
    "Играть с бустером": "+40%"
  };

  // Updated handleChange function
  const handleChange = async (
    type: 'rank' | 'server' | 'platform' | 'setting',
    value: string | boolean,
    settingKey?: string
  ) => {
    let newState = { ...state };

    switch (type) {
      case 'rank':
        if (typeof value === 'string' && settingKey) {
          if (settingKey === 'current') {
            newState.currentRank = value;
            // Set desired rank to the same rank with next RR range or keep it if it's already higher
            const [currentRankName, currentDivision, currentRR] = value.split(' ');
            const currentRRIndex = ['0-18', '19-36', '37-54', '55-72', '73-89'].indexOf(currentRR);
            const [desiredRankName, desiredDivision, desiredRR] = newState.desiredRank.split(' ');
            
            if (getRankValue(newState.desiredRank) <= getRankValue(value)) {
              if (currentRRIndex < 4) {
                newState.desiredRank = `${currentRankName} ${currentDivision} ${['19-36', '37-54', '55-72', '73-89'][currentRRIndex]}`;
              } else {
                // If current RR is the highest, move to next division or rank
                if (currentDivision === 'III' || currentRankName === 'Radiant') {
                  const currentRankIndex = rankOrder.indexOf(currentRankName);
                  const nextRankName = rankOrder[currentRankIndex + 1];
                  newState.desiredRank = nextRankName === 'Radiant' ? 'Radiant' : `${nextRankName} I 0-18`;
                } else {
                  const nextDivision = currentDivision === 'I' ? 'II' : 'III';
                  newState.desiredRank = `${currentRankName} ${nextDivision} 0-18`;
                }
              }
            }
          } else {
            newState.desiredRank = value;
          }
        }
        break;
      case 'server':
        if (typeof value === 'string') newState.selectedServer = value;
        break;
      case 'platform':
        if (typeof value === 'string') newState.selectedPlatform = value;
        break;
      case 'setting':
        if (typeof settingKey === 'string' && typeof value === 'boolean') {
          newState.settings = { ...newState.settings, [settingKey]: value };
        }
        break;
    }

    setState(newState);

    // Cancel the previous request if it's still ongoing
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for this request
    abortControllerRef.current = new AbortController();

    try {
      const response = await sendDataToServer(newState, abortControllerRef.current.signal);
      if (response.success) {
        setState(prevState => ({
          ...prevState,
          estimatedHours: response.estimatedHours,
          price: response.estimatedPrice,
          boostPrice: response.boostPrice
        }));
      }
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error('Error updating price:', error);
      }
    }
  };

  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__main">
          <div className="container container_secondary">
            <div className="layout__boost boost">
              <div className="boost__banner banner">
                <picture className="banner__media">
                  <img src="/img/content/boost/boost__background.jpg" alt="Boost background" />
                </picture>
                <div className="banner__main">
                  <a className="btn btn_tiny" href="#">
                    <span className="btn__text">Ranked</span>
                  </a>
                </div>
              </div>
              <div className="boost__calc">
                <RankSelector
                  selectedRank={state.currentRank}
                  onRankChange={(value: string) => handleChange('rank', value, 'current')}
                  label="Текущий ранг"
                  isEndRank={false}
                />
                <RankSelector
                  selectedRank={state.desiredRank}
                  onRankChange={(value: string) => handleChange('rank', value, 'desired')}
                  label="Желаемый ранг"
                  isEndRank={true}
                  startRank={state.currentRank}
                />
                <div className="calc__group">
                  <div className="calc__header">
                    <div className="calc__title">
                      <span className="calc__text">Регион</span>
                      <div className="form">
                        <div className="form__item form__item_secondary">
                          <label className="form__label">
                            <select 
                              className="form__input"
                              value={state.selectedServer}
                              onChange={(e) => handleChange('server', e.target.value)}
                            >
                              <option value="Europe">Europe</option>
                              <option value="North America">North America</option>
                              <option value="Asia">Asia</option>
                            </select>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="calc__group">
                  <div className="calc__header">
                    <div className="calc__title">
                      <h6 className="calc__text">Доп. услуги</h6>
                    </div>
                  </div>
                  <div className="calc__main">
                    <div className="layout__settings settings">
                      <div className="settings__list">
                        {Object.entries(state.settings).map(([key, value]) => (
                          <div key={key} className="settings__item">
                            <div className="settings__header">
                              <div className="settings__title">
                                <span className="settings__text">{key}</span>
                              </div>
                              <div className="settings__tooltip tooltip">
                                <svg className="tooltip__symbol">
                                  <use xlinkHref="/img/sprite.svg#tooltip"></use>
                                </svg>
                              </div>
                            </div>
                            <div className="settings__main">
                              <div className="settings__status">
                                <span className="settings__text">{settingPrices[key as keyof typeof settingPrices]}</span>
                              </div>
                              <div className="settings__switcher switcher">
                                <label className="switcher__label">
                                  <input
                                    className="switcher__input"
                                    type="checkbox"
                                    name={key}
                                    checked={value}
                                    onChange={() => handleChange('setting', !value, key)}
                                  />
                                  <i className="switcher__ico"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="calc__group">
                  <div className="calc__header">
                    <div className="calc__title">
                      <h6 className="calc__text">Выберите платформу</h6>
                    </div>
                  </div>
                  <div className="calc__main">
                    <ul className="calc__list calc__list_secondary">
                      {platforms.map(platform => (
                        <li 
                          key={platform.name} 
                          className={`calc__item ${state.selectedPlatform === platform.name ? 'calc__item_active' : ''} ${platform.disabled ? 'calc__item_disabled' : ''}`}
                          onClick={() => !platform.disabled && handleChange('platform', platform.name)}
                        >
                          <svg className="calc__ico">
                            <use xlinkHref={`/img/sprite.svg#${platform.icon}`}></use>
                          </svg>
                          <span className="calc__text">{platform.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <aside className="boost__order order">
                <div className="order__header">
                  <h6 className="order__title">Резюме</h6>
                  <span className="order__time time">
                    <span className="time__text">~{state.estimatedHours}h</span>
                    <svg className="time__ico">
                      <use xlinkHref="/img/sprite.svg#clock"></use>
                    </svg>
                  </span>
                </div>
                <div className="order__main">
                  <div className="order__data data">
                    <div className="data__list">
                      <div className="data__item">
                        <div className="data__subitem">
                          <span className="data__text">{state.currentRank} - {state.desiredRank}</span>
                        </div>
                        <div className="data__subitem">
                          <span className="data__text data__text_secondary">${state.boostPrice}</span>
                        </div>
                      </div>
                      <div className="data__item">
                        <div className="data__subitem">
                          <span className="data__text">{state.selectedServer}</span>
                        </div>
                        <div className="data__subitem">
                          <span className="data__text data__text_secondary">Бесплатно</span>
                        </div>
                      </div>
                      {Object.entries(state.settings).map(([key, value]) => (
                        value && (
                          <div key={key} className="data__item">
                            <div className="data__subitem">
                              <span className="data__text">{key}</span>
                            </div>
                            <div className="data__subitem">
                              <span className="data__text data__text_secondary">{settingPrices[key as keyof typeof settingPrices]}</span>
                            </div>
                          </div>
                        )
                      ))}
                    </div>
                    <div className="data__list">
                      <div className="data__item">
                        <div className="data__subitem">
                          <span className="data__text">Итоговая сумма</span>
                        </div>
                        <div className="data__subitem">
                          <span className="data__text data__text_secondary">${state.price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar__footer">
                  <a className="btn btn_middle">
                    <span className="btn__text">Оформить заказ</span>
                    <span className="btn__subtext">${state.boostPrice}</span>
                  </a>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

