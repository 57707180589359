import React from 'react'
function Button(props: any) {
  return (
    <React.Fragment>
      <button
          className={`btn ${props.addClasses}`}
          onClick={() => {props.buttonCallback && (props.buttonCallback())}}>
        {props.ico && props.before && <span className='btn__ico'>{props.ico}</span>}
        {props.text && <span className='btn__text'>{props.text}</span>}
        {props.ico && props.after && <span className='btn__ico'>{props.ico}</span>}
      </button>
    </React.Fragment>
  )
}
export default Button
