import React from "react";
function preview(props: any) {
  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__main">
          <div className="container container_secondary">
            <div className="layout__preview preview">
              <picture className="preview__picture">
                <img
                  src="/img/content/preview/preview__img01.png"
                  alt="image description"
                />
              </picture>
              <div className="preview__steps steps">
                <div className="steps__header">
                  <ul className="steps__list">
                    <li className="steps__item">
                      <span className="steps__text">Играй</span>
                      <svg className="steps__ico">
                        <use xlinkHref="/img/sprite.svg#arrowRight"></use>
                      </svg>
                    </li>
                    <li className="steps__item">
                      <span className="steps__text">Зарабатывай</span>
                      <svg className="steps__ico">
                        <use xlinkHref="/img/sprite.svg#arrowRight"></use>
                      </svg>
                    </li>
                    <li className="steps__item">
                      <span className="steps__text">Наслаждайся</span>
                    </li>
                  </ul>
                </div>
                <div className="steps__main">
                  <h2 className="steps__title">
                    VALORANT<br></br>
                    <strong>BOOSTING</strong>
                    <br></br> SERVICE
                  </h2>
                </div>
                <div className="steps__footer">
                  <a className="btn" href="boost">
                    <span className="btn__text">Заказать сейчас!</span>
                  </a>
                </div>
              </div>
              <div className="preview__reviews reviews">
                <div className="reviews__main">
                  <div className="reviews__list">
                    <div className="reviews__item">
                      <picture className="reviews__picture">
                        <img
                          className="reviews__img"
                          src="/img/buyer1.webp"
                          alt="image description"
                        />
                      </picture>
                      <div className="reviews__content">
                        <span className="reviews__title">
                          cutthroatpublicenemy
                        </span>
                        <span className="reviews__text">+3150</span>
                      </div>
                    </div>
                    <div className="reviews__item">
                      <picture className="reviews__picture">
                        <img
                          className="reviews__img"
                          src="/img/buyer2.webp"
                          alt="image description"
                        />
                      </picture>
                      <div className="reviews__content">
                        <span className="reviews__title">sh3stoy</span>
                        <span className="reviews__text">+412</span>
                      </div>
                    </div>
                    <div className="reviews__item">
                      <picture className="reviews__picture">
                        <img
                          className="reviews__img"
                          src="/img/buyer3.webp"
                          alt="image description"
                        />
                      </picture>
                      <div className="reviews__content">
                        <span className="reviews__title">herofaceit</span>
                        <span className="reviews__text">+1200</span>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="https://www.trustpilot.com/review/csmvalorant.com">
                  <div className="reviews__footer">
                    <div className="reviews__statistics statistics">
                      <span className="statistics__text">Review us on</span>

                      <svg className="statistics__ico">
                        <use xlinkHref="/img/sprite.svg#starSecondary"></use>
                      </svg>
                      <span className="statistics__text">Trustpilot</span>
                    </div>
                  </div>
                </a>
              </div>

              <div className="preview__scroll scroll">
                <svg className="scroll__ico">
                  <use xlinkHref="/img/sprite.svg#chevronBottom"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default preview;
