import React, { useState } from "react";
import Preview from "../components/sections/preview/Preview";
import Boosters from "../components/sections/boosters/Boosters";
import Carousel from "../components/sections/carousel/Carousel";
import Services from "../components/sections/services/Services";
function Home() {
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState(false);
  const buttonCallback = () => {
    console.log(123);
  };
  return (
    <React.Fragment>
      <div className="layout__main layout__main_home">
        <Preview />
        <Carousel />
        <Boosters />
        <Services />
      </div>
    </React.Fragment>
  );
}
export default Home;
