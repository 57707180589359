import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import './auth-modals.css'
import { useAuth } from '../../../components/global/AuthContext'

// Simple asynchronous function to verify the code
const verifyCode = async (code: string): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(code === '123456')
    }, 1000) // Simulate network delay
  })
}

interface AuthModalsProps {
  isOpen: boolean
  onClose: () => void
}

export function AuthModals({ isOpen, onClose }: AuthModalsProps) {
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [currentStep, setCurrentStep] = useState<'email' | 'code'>('email')
  const [isMounted, setIsMounted] = useState(false)
  const [timer, setTimer] = useState(60)
  const [canResend, setCanResend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { login } = useAuth();

  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (isOpen && currentStep === 'code' && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (timer === 0) {
      setCanResend(true)
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [isOpen, currentStep, timer])

  const resetState = () => {
    setEmail('')
    setCode('')
    setCurrentStep('email')
    setTimer(60)
    setCanResend(false)
    setIsLoading(false)
    setError(null)
  }

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setCurrentStep('code')
  }

  const handleCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      const isValid = await verifyCode(code)
      if (isValid) {
        // onAuthComplete(email, code)
        const userData = {
          name: email,
          token: "",
          avatar_url: "/img/avatar.png",
        };
        login(userData);
        onClose()
    
        resetState()
      } else {
        setError('Неверный код. Пожалуйста, попробуйте еще раз.')
      }
    } catch (err) {
      setError('Произошла ошибка при проверке кода. Пожалуйста, попробуйте еще раз.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    resetState()
    onClose()
  }

  const handleResendCode = () => {
    console.log('Resending code to', email)
    setTimer(60)
    setCanResend(false)
  }

  useEffect(() => {
    if (!isOpen) {
      resetState()
    }
  }, [isOpen])

  if (!isOpen || !isMounted) return null

  const modalContent = (
    <div className="modal-overlay">
      <div className={`modal-content ${isOpen ? 'active' : ''}`}>
        <div className="modal-header">
          <h2 className="modal-title">
            {currentStep === 'email' ? 'Вход' : 'Введите код'}
          </h2>
          <button onClick={handleClose} className="modal-close">
            ✕
          </button>
        </div>

        {currentStep === 'email' ? (
          <form onSubmit={handleEmailSubmit} className="modal-form">
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Электронная почта
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
                placeholder="example@email.com"
                required
              />
            </div>

            <button type="submit" className="form-submit">
              Войти
            </button>
          </form>
        ) : (
          <>
            <p className="text-gray-600 mb-4">Код отправлен на {email}</p>
            <form onSubmit={handleCodeSubmit} className="modal-form">
              <div className="form-group">
                <label htmlFor="code" className="form-label">
                  Код подтверждения
                </label>
                <input
                  id="code"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="form-input"
                  placeholder="Введите код из email"
                  required
                />
              </div>
              {error && <p className="error-message">{error}</p>}
              <button type="submit" className="form-submit" disabled={isLoading}>
                {isLoading ? 'Проверка...' : 'Подтвердить'}
              </button>
            </form>

            <div className="resend-code">
              {canResend ? (
                <button onClick={handleResendCode} className="resend-button">
                  Отправить код повторно
                </button>
              ) : (
                <p>
                  Отправить повторно через {timer} сек
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )

  return createPortal(modalContent, document.body)
}

