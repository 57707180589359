import React, { useState } from "react";
import Input from "../components/form/Input/Input";
import Checkbox from "../components/form/Checkbox/Checkbox";
import Textarea from "../components/form/Textarea/Textarea";
import Button from "../components/form/Button/Button";
function UiKit() {
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState(false);
  const buttonCallback = () => {
    console.log(123);
  };
  return (
    <React.Fragment>
      <Input
        placeholder={"placeholder"}
        value={value}
        pattern={""}
        inputCallback={setValue}
        addClasses={["w-100"]}
      />

      <Textarea
        placeholder={"placeholder"}
        value={value2}
        pattern={""}
        textareaCallback={setValue2}
        addClasses={["w-100"]}
      />

      <Checkbox
        id={"IMadeAPayment"}
        value={value3}
        addClasses={["w-100"]}
        type={"checkbox"}
        text={"placeholder"}
        status={true}
        checkboxCallback={setValue3}
      />

      <Button
        buttonCallback={buttonCallback}
        addClasses={["w-100"]}
        text={"placeholder"}
      />
    </React.Fragment>
  );
}
export default UiKit;
