import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Thankyoupage from "./pages/Thankyoupage";
import Boost from "./pages/Boosting";
import UiKit from "./pages/UiKit";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import HandleRedirect from "./components/global/HandleRedirect";
import { AuthProvider } from "./components/global/AuthContext";
import { AuthModals } from './components/sections/auth/auth-modals'
import  DataPolicy  from './pages/DataPolicy'
import  RefundPolicy  from './pages/RefundPolicy'
import  UserAgrement  from './pages/UserAgrement'

import { useState } from 'react'

function App() {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)

  const handleAuthComplete = (email: string, code: string) => {
    console.log('Authentication completed:', email, code)
    // Handle authentication logic here
    setIsAuthModalOpen(false)
  }

  return (
    <React.Fragment>
      <div className="layout">
        <Router>
          <AuthProvider>
          <HandleRedirect />
          <Header onOpenAuthModal={() => setIsAuthModalOpen(true)}  />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dm" element={<Blog />} />
            <Route path="/boost" element={<Boost />} />
            <Route path="/thankyoupage" element={<Thankyoupage />} />
            <Route path="/uikit" element={<UiKit />} />
            <Route path="/privacy-policy" element={<DataPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-of-use" element={<UserAgrement />} />
          </Routes>
          <AuthModals 
              isOpen={isAuthModalOpen}
              onClose={() => setIsAuthModalOpen(false)}
            />
           <Footer />
          </AuthProvider>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
