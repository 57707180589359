import React from "react";
function Input(props: any) {
  return (
    <React.Fragment>
      <input
        id={props.id}
        className={`form__input ${props.addClasses}`}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        pattern={props.pattern}
        onChange={(e) => props.inputCallback(e.target.value)}
      />
    </React.Fragment>
  );
}
export default Input;
