import React from "react";
import Report from "../components/sections/report/Report";
function Thankyoupage() {
  return (
    <div className="layout__main layout__main_thankyoupage">
      <Report />
    </div>
  );
}
export default Thankyoupage;
