import React from "react";
function Checkbox(props: any) {
  let checkboxIcoBefore = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke="#A717FF"
          strokeWidth="2"
        />
      </svg>
    ),
    checkboxIcoAfter = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke="#A717FF"
          strokeWidth="2"
        />
        <path d="M5 11L9 15L15 5" stroke="#A717FF" strokeWidth="2" />
      </svg>
    ),
    radioIcoBefore = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke="#A717FF"
          strokeWidth="2"
        />
      </svg>
    ),
    radioIcoAfter = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke="#A717FF"
          strokeWidth="2"
        />
        <g filter="url(#filter0_f_4998_22958)">
          <rect x="4" y="4" width="12" height="12" rx="6" fill="white" />
          <rect
            x="5"
            y="5"
            width="10"
            height="10"
            rx="5"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <rect x="5" y="5" width="10" height="10" rx="5" fill="#A717FF" />
        <defs>
          <filter
            id="filter0_f_4998_22958"
            x="0"
            y="0"
            width="20"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="2"
              result="effect1_foregroundBlur_4998_22958"
            />
          </filter>
        </defs>
      </svg>
    );
  return (
    <React.Fragment>
      <div className="custom-check">
        <label className="custom-check__label">
          <input
            type={props.type}
            className="custom-check__input"
            id={props.id}
            name={props.name}
            value={props.value}
            checked={props.status}
            disabled={props.disabled}
            onChange={() => props.checkboxCallback()}
          />
          <div className="checkbox__media">
            {props.type === "checkbox" && (
              <React.Fragment>
                <div className="checkbox__ico checkbox__ico_before">
                  {checkboxIcoBefore}
                </div>
                <div className="checkbox__ico checkbox__ico_after">
                  {checkboxIcoAfter}
                </div>
              </React.Fragment>
            )}
            {props.type === "radio" && (
              <React.Fragment>
                <div className="checkbox__ico checkbox__ico_before">
                  {radioIcoBefore}
                </div>
                <div className="checkbox__ico checkbox__ico_after">
                  {radioIcoAfter}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="checkbox__main">
            <span className="checkbox__text">{props.text}</span>
          </div>
        </label>
      </div>
    </React.Fragment>
  );
}
export default Checkbox;
