function UserAgrement() {
    return (
      <div className="layout__main layout__main_blog">
    <h1>Пользовательское Соглашение</h1>

<h2>Общие положения и определения</h2>

<h3>1.1.</h3>
<p>Настоящее Пользовательское Соглашение (далее – «Соглашение») является юридически обязательным соглашением между физическим или юридическим лицом (далее – «Клиент») и частным предприятием <strong>CSMBoost</strong> (далее – «Компания»), зарегистрированным в соответствии с законодательством Российской Федерации.</p>

<h3>1.2.</h3>
<p>Акцептом данного Соглашения считается размещение заказа, оплата услуг или иное использование функционала веб-сайта Компании (далее – «Сайт»). Акцепт в соответствии с п.2 ст.437 ГК РФ подтверждает безусловное согласие Клиента с условиями Соглашения.</p>

<h3>1.3. Определения:</h3>
<ul>
    <li><strong>«Услуги»</strong> – совокупность посреднических и сопутствующих услуг Компании по организации взаимодействия между Клиентом и третьими лицами (Исполнителями), включая услуги бустинга игровых аккаунтов, продвижения игровых профилей (в т.ч. Steam, CS2, Valorant, Apex Legends и др.), увеличения внутриигровых показателей и иные сервисные операции.</li>
    <li><strong>«Исполнитель»</strong> – третье лицо (физическое или юридическое), оказывающее Клиенту услуги по заданию Компании.</li>
    <li><strong>«Аккаунт Клиента»</strong> – учетная запись Клиента на Сайте.</li>
</ul>

<h3>1.4.</h3>
<p>Термины, не определенные в настоящем Соглашении, толкуются в соответствии с законодательством РФ, контекстом Соглашения или внутренними документами Компании, опубликованными на Сайте.</p>

<h3>1.5.</h3>
<p>Компания вправе в одностороннем порядке вносить изменения в настоящее Соглашение путем размещения обновлённой редакции на Сайте. Продолжение использования Услуг после внесения изменений означает согласие Клиента с обновлённой редакцией.</p>

<h3>1.6.</h3>
<p>Акцептуя настоящее Соглашение, Клиент также подтверждает свое согласие со всеми действующими внутренними документами Компании (включая, но не ограничиваясь Политикой конфиденциальности, правилами пользования Сайтом, иными опубликованными на Сайте регламентами).</p>

<h3>1.7.</h3>
<p>Клиент соглашается, что Компания вправе использовать предоставленные Клиентом данные (за исключением персональных данных, использование которых регулируется Политикой конфиденциальности) для улучшения качества Услуг, аналитики, внутреннего контроля, а также для информационно-маркетинговой деятельности, при условии соблюдения применимого законодательства.</p>

<h3>1.8.</h3>
<p>Компания может использовать предоставленные Клиентом данные и доступ к его игровому аккаунту для выполнения необходимых действий по оказанию Услуг, включая, но не ограничиваясь, оставлением комментариев в профиле Steam, вступлением в сообщества, отправкой запросов на добавление в друзья и иными действиями, напрямую необходимыми для достижения заявленных целей Услуг.</p>

<h2>Права и обязанности сторон</h2>

<h3>Права и обязанности Клиента:</h3>
<ul>
    <li><strong>2.1.</strong> Клиент обязан предоставлять достоверные данные при оформлении заказа, оплате Услуг и предоставлении доступа к игровому аккаунту (при необходимости).</li>
    <li><strong>2.2.</strong> Клиент обязан воздерживаться от действий, затрудняющих оказание Услуг, соблюдать правила игровых платформ и Сайта.</li>
    <li><strong>2.3.</strong> Клиент вправе получать консультации, информацию о ходе оказания Услуг, вносить изменения в заказ до начала фактического исполнения, если это допускается политикой Компании.</li>
    <li><strong>2.4.</strong> Клиент несет риск неблагоприятных последствий, связанных с предоставлением неверных данных, несоблюдением условий Соглашения или нарушением правил игровых платформ.</li>
    <li><strong>2.5.</strong> Клиент обязан самостоятельно и до момента размещения заказа на Услугу ознакомиться с её полным описанием, условиями предоставления и ограничениями. Незнание или неполное изучение описания Услуг не может служить основанием для изменения условий оказания Услуг, требования дополнительных функций, не предусмотренных описанием, или для возврата денежных средств в случае несоответствия ожиданий Клиента фактическому содержанию Услуги.</li>
</ul>

<h3>Права и обязанности Исполнителя:</h3>
<ul>
    <li><strong>3.1.</strong> Исполнитель обязан добросовестно и своевременно выполнять заказы в соответствии с указаниями Компании и доведенными до Клиента условиями.</li>
    <li><strong>3.2.</strong> Исполнитель вправе запрашивать у Клиента дополнительную информацию, необходимую для выполнения заказа. Отказ Клиента предоставить такую информацию может повлечь прекращение оказания Услуг без компенсации.</li>
</ul>

<h3>Права и обязанности Компании:</h3>
<ul>
    <li><strong>4.1.</strong> Компания осуществляет посредническую деятельность, организуя взаимодействие между Клиентом и Исполнителем, но не является стороной сделок, касающихся внутриигровых ценностей или аккаунтов.</li>
    <li><strong>4.2.</strong> Компания вправе отказать или прекратить оказание Услуг при нарушении Клиентом положений Соглашения, предоставлении недостоверных данных или невыполнении инструкций Компании.</li>
    <li><strong>4.3.</strong> Компания вправе изменять стоимость, сроки, перечень и условия оказания Услуг с уведомлением через Сайт. Продолжение использования Услуг считается акцептом новых условий.</li>
    <li><strong>4.4.</strong> Компания не гарантирует достижение каких-либо конкретных результатов и не несет ответственности за действия Исполнителей, качество услуг Исполнителя или последствия, вызванные санкциями со стороны игровых платформ.</li>
</ul>

<h2>Оплата</h2>
<ul>
    <li><strong>5.1.</strong> Оплата Услуг осуществляется исключительно способами, указанными на Сайте. Клиент обязан использовать корректные платежные данные.</li>
    <li><strong>5.2.</strong> Компания вправе изменять цены на Услуги в одностороннем порядке. Продолжение заказа после изменения стоимости означает согласие Клиента с новой ценой.</li>
</ul>

<h2>Возвраты</h2>
<ul>
    <li><strong>6.1.</strong> Возвраты средств за неоказанные по вине Компании или Исполнителя Услуги осуществляются на карту.</li>
    <li><strong>6.2.</strong> Из возвращаемой суммы удерживаются фактически понесенные Компанией расходы.</li>
    <li><strong>6.3.</strong> Право на возврат утрачивается, если Услуга фактически начала оказываться (включая подготовительные этапы) или Клиент отказался от Услуги после ее частичного исполнения.</li>
    <li><strong>6.4.</strong> Клиент обязан своевременно сообщить Компании о желании отменить Услугу до начала ее фактического исполнения. В случае спорных ситуаций по вопросам возврата средств бремя доказательства вины Компании или Исполнителя лежит на Клиенте. При отсутствии прямых доказательств указанных нарушений возврат средств не осуществляется.</li>
</ul>

<h2>Условия предоставления услуг</h2>

<h3>Общие положения:</h3>
<ul>
    <li><strong>7.1.</strong> Все Услуги оказываются «как есть» без каких-либо явных или подразумеваемых гарантий непрерывности, безошибочности, достижения определенных результатов или коммерческой ценности.</li>
    <li><strong>7.2.</strong> Компания прилагает разумные усилия для обеспечения работоспособности Сайта, но не гарантирует отсутствие перерывов по техническим или иным причинам.</li>
    <li><strong>7.3.</strong> Клиент признает и соглашается, что покупка Услуг не изменяет право собственности на внутриигровые предметы, ценности или результаты, а также не гарантирует сохранение достигнутых показателей, особенно если игровые платформы применят блокировки или санкции.</li>
    <li><strong>7.4.</strong> Условия использования Discord. Для оформления заказа, получения услуг и управления процессом их предоставления Клиент обязан использовать приложение Discord. Компания не предоставляет альтернативных способов взаимодействия для указанных операций. Несоблюдение этого требования может привести к невозможности оказания Услуг без обязательства Компании компенсировать затраты или возвращать средства.</li>
    <li><strong>7.5.</strong> Условия буста Faceit. Услуга буста на платформе Faceit для игры CS2 доступна исключительно для аккаунтов, на которых имеется не менее 150 завершенных матчей. Клиент обязан самостоятельно убедиться в соответствии данного требования своему аккаунту до оформления заказа. Несоблюдение этого условия может привести к отказу в оказании Услуги без обязательства Компании возвращать средства.</li>
</ul>

<h2>Политика блокировок:</h2>
<ul>
    <li><strong>8.1.</strong> Использование Услуг, связанных с игровыми аккаунтами, может сопровождаться риском блокировок или санкций со стороны игровых платформ.</li>
    <li><strong>8.2.</strong> Компания не несет ответственности за последствия указанных действий игровых платформ.</li>
    <li><strong>8.3.</strong> Клиент самостоятельно несет ответственность за проверку допустимости использования Услуг в соответствии с правилами и политиками игровых платформ. Клиент осознает и соглашается, что нарушение таких правил может привести к блокировкам, санкциям или ограничению доступа, за которые Компания ответственности не несет.</li>
</ul>

<h2>Услуги бустинга:</h2>
<ul>
    <li><strong>9.1.</strong> Бустинг подразумевает повышение показателей игрового аккаунта (ранг, статистика и т.п.).</li>
    <li><strong>9.2.</strong> Клиент обязан не препятствовать процессу буста (например, не заходить в аккаунт). Нарушение данного условия освобождает Компанию от каких-либо обязательств по возврату средств.</li>
</ul>

<h2>Услуги продвижения аккаунта:</h2>
<ul>
    <li><strong>10.1.</strong> Компания может оказывать услуги по увеличению внутриигровых показателей (лайки, время в игре, комментарии и т.п.).</li>
    <li><strong>10.2.</strong> Компания не гарантирует, что такие меры повлияют на внутриигровые рейтинги, репутацию или иные скрытые показатели.</li>
</ul>

<h2>Отказ от гарантий и ограничение ответственности</h2>
<ul>
    <li><strong>11.1.</strong> Компания, Исполнитель и любые их аффилированные лица не несут ответственности за любые косвенные, случайные, опосредованные, штрафные или иные убытки, упущенную выгоду, потерю данных, прерывание деловой активности или иные неблагоприятные последствия, возникшие в результате использования или невозможности использования Услуг.</li>
    <li><strong>11.2.</strong> Максимальная совокупная ответственность Компании перед Клиентом в любом случае ограничивается суммой денежных средств, зачисленных на внутренний баланс Аккаунта Клиента.</li>
</ul>

<h2>Чёткое разделение ролей</h2>
<ul>
    <li><strong>12.1.</strong> Компания выступает как посредник и информационная платформа, облегчающая взаимодействие между Клиентом и Исполнителем, не участвуя непосредственно в выполнении услуг Исполнителя или распоряжении внутриигровыми ценностями.</li>
    <li><strong>12.2.</strong> Клиент самостоятельно отвечает за соблюдение правил игровых платформ, предоставление корректных данных и доступов, а также за оценку соответствия Услуг своим потребностям.</li>
    <li><strong>12.3.</strong> Исполнитель несёт ответственность только в пределах предоставленных ему полномочий и согласно условиям, доведённым Компанией до Клиента.</li>
</ul>

<h2>Заключительные положения</h2>
<ul>
    <li><strong>13.1.</strong> Соглашение вступает в силу с момента его размещения на Сайте и действует бессрочно, пока не будет изменено или прекращено Компанией.</li>
    <li><strong>13.2.</strong> Компания вправе в одностороннем порядке вносить изменения в Соглашение, размещая обновлённый текст на Сайте. Продолжение использования Услуг после внесения изменений означает согласие Клиента с обновлённой редакцией.</li>
    <li><strong>13.3.</strong> В случае несогласия с изменениями Клиент обязан немедленно прекратить использование Услуг.</li>
    <li><strong>13.4.</strong> Недействительность отдельного положения Соглашения не влечет недействительности Соглашения в целом. Иные положения остаются в силе.</li>
    <li><strong>13.5.</strong> Все претензии и споры, возникающие из настоящего Соглашения, подлежат рассмотрению в порядке, предусмотренном действующим законодательством РФ. Клиент обязуется направить претензию Компании в письменном виде по указанным на Сайте контактам. В случае неудовлетворительного ответа или его отсутствия, Клиент вправе обратиться в суд по месту нахождения Компании.</li>
    <li><strong>13.6.</strong> Применимое право – законодательство Российской Федерации. Все неурегулированные вопросы разрешаются в соответствии с законодательством РФ.</li>
    <li><strong>13.7.</strong> Компания не несет ответственности за невыполнение или просрочку в выполнении своих обязательств по настоящему Соглашению, вызванные обстоятельствами непреодолимой силы (форс-мажор), включая, но не ограничиваясь: стихийными бедствиями, военными действиями, актами терроризма, кибератаками, забастовками, сбоями в работе инфраструктуры интернет-провайдеров, массовыми сбоями в работе игровых платформ, действиями государственных органов или иными обстоятельствами, находящимися вне разумного контроля Компании.</li>
</ul>
</div>
    );
  }
  export default UserAgrement;
  