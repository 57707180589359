import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const boostersData = [
  {
    id: 3,
    name: "Ins",
    role: "Duelist",
    avatar: "/img/boosters/ins.webp",
    score: "/img/stars.png",
    rank: "Radiant",
    region: "EUW",
    link: "https://tracker.gg/valorant/profile/riot/ins%23ult/overview"
  },
  {
    id: 4,
    name: "sladki snov",
    role: "Duelist",
    avatar: "/img/boosters/sladki_snov.webp",
    score: "/img/stars.png",
    rank: "Radiant",
    region: "EUW",
    link: "https://tracker.gg/valorant/profile/riot/sladki%20snov%23aqua/overview"
  },
  {
    id: 2,
    name: "Bewix",
    role: "Duelist",
    avatar: "/img/boosters/bewix.webp",
    score: "/img/stars.png",
    rank: "Radiant",
    region: "EUW",
    link: "https://tracker.gg/valorant/profile/riot/BLG%20Bewix%23slurp/overview"
  },
  {
    id: 1,
    name: "yaami4",
    role: "Duelist",
    avatar: "/img/boosters/yaami4.png",
    score: "/img/stars.png",
    rank: "immortal II",
    region: "EUW",
    link: "https://tracker.gg/valorant/profile/riot/yaami4%23ban/overview"
  },
];

function boosters(props: any) {
  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__header">
          <div className="container container_secondary">
            <div className="section__title">
              <h1 className="section__text">Бустеры</h1>
            </div>
            <div
              className="section__navigation navigation"
              data-name="boosters"
            >
              <div className="navigation__item navigation__item_prev">
                <svg className="navigation__ico">
                  <use xlinkHref="/img/sprite.svg#navigationPrev"></use>
                </svg>
              </div>
              <div className="navigation__item navigation__item_next">
                <svg className="navigation__ico">
                  <use xlinkHref="/img/sprite.svg#navigationNext"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="section__main">
          <div className="container container_secondary">
            <div className="layout__boosters boosters">
              <Swiper
                modules={[Navigation]}
                className="boosters__list"
                spaceBetween={20}
                slidesPerView={3}
                navigation={{
                  prevEl: "[data-name='boosters'] .navigation__item_prev",
                  nextEl: "[data-name='boosters'] .navigation__item_next",
                }}
              >
                {boostersData.map((booster) => (
                  <SwiperSlide key={booster.id} className="boosters__item">
                    <a
                      className="boosters__link"
                      href={booster.link}
                      onClick={(e) => {
                        if (!booster.link) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="boosters__header">
                        <picture className="boosters__media">
                          <img src={booster.avatar} alt={`${booster.name}'s avatar`} />
                        </picture>
                        <h6 className="boosters__title">{booster.name}</h6>
                        <span className="boosters__subtitle">{booster.role}</span>
                      </div>
                      <div className="boosters__main">
                        <div className="boosters__data data">
                          <div className="data__list">
                            <div className="data__item">
                              <div className="data__header">
                                <span className="data__title">Score</span>
                              </div>
                              <div className="data__main">
                                <img
                                  className="data__img"
                                  src={booster.score}
                                  alt="image description"
                                />
                              </div>
                            </div>
                            <div className="data__item">
                              <div className="data__header">
                                <span className="data__title">Rank</span>
                              </div>
                              <div className="data__main">
                                <span className="data__text">{booster.rank}</span>
                              </div>
                            </div>
                            <div className="data__item">
                              <div className="data__header">
                                <span className="data__title">Region</span>
                              </div>
                              <div className="data__main">
                                <span className="data__text">{booster.region}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default boosters;
