import React from "react";
import Messenger from "../components/sections/messenger/Messenger";
function Blog() {
  return (
    <div className="layout__main layout__main_blog">
      <Messenger />
    </div>
  );
}
export default Blog;
