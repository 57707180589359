import React from "react";
import { NavLink } from "react-router-dom";
function Report(props: any) {
  return (
    <React.Fragment>
      <section className="layout__section section">
        <div className="section__main">
          <div className="container container_secondary">
            <div className="layout__report report">
              <header className="report__header">
                <h1 className="report__title">СПАСИБО ЗА&nbsp;ПОКУПКУ!</h1>
              </header>
              <main className="report__main">
                <span className="report__text">
                  Мы будем очень признательны, если вы поделитесь своими
                  впечатлениями о&nbsp;магазине и&nbsp;посоветуете его друзьям!
                </span>
              </main>
              <footer className="report__footer">
                <a className="btn" href="/dm">
                  <span className="btn__text">Перейти в чат</span>
                </a>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default Report;
