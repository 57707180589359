"use client"

import React, { useRef, useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCoverflow, Navigation } from "swiper/modules"
import { Autoplay } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

interface CarouselItem {
  id: number
  title: string
  price: number
  image: string
  link: string
}

function Carousel() {
  const swiperRef = useRef<SwiperType | null>(null);

  // State to track if the page has fully loaded
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  // Carousel items
  const [items] = useState<CarouselItem[]>([
    {
      id: 1,
      title: "ВАЛЮТА НА 11000VP EU/RU",
      price: 9099,
      image: "https://sun159-1.userapi.com/impg/Bh5HzvZ-PYSOXyoE3rlQxdxx0ma6DJNM3ozXPQ/yYpYyWmw6mc.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=e7d29b58758f60679b1fa81e6ea26b88",
      link: "https://vk.com/market/product/valyuta-na-11000vp-euru-227636417-10439376"
    },
    {
      id: 2,
      title: "ВАЛЮТА НА 5350VP EU/RU",
      price: 4499,
      image: "https://sun159-2.userapi.com/impg/xNLHjoYvDcdtnaBDmOhYYGWdcE5e1NlUnXliMQ/7QlItlb5sJc.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=7738a1d4d0d6afb91e6b7fa964d4f7a8",
      link: "https://vk.com/market/product/valyuta-na-5350vp-euru-227636417-10439364"
    },
    {
      id: 3,
      title: "ВАЛЮТА НА 3650VP EU/RU",
      price: 3149,
      image: "https://sun159-2.userapi.com/impg/OHNNiM3w23gmtPClbYOSOeIxDCjF1pTtp60x3Q/gb3HEJghhVw.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=da2519b20595579c227393d24643d67c",
      link: "https://vk.com/market/product/valyuta-na-3650vp-euru-227636417-10439358"
    },
    {
      id: 4,
      title: "ВАЛЮТА НА 2575VP EU/RU",
      price: 2299,
      image: "https://sun159-2.userapi.com/impg/svoy08G7o_9vIKp3cj25OSFsrjafo_imhfscAw/DNdsqORBbkU.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=27308ab08d318adba35e3f968e75775c",
      link: "https://vk.com/market/product/valyuta-na-2575vp-euru-227636417-10439348"
    },
    {
      id: 5,
      title: "ВАЛЮТА НА 2050VP EU/RU",
      price: 1799,
      image: "https://sun159-1.userapi.com/impg/SNl1PDwSh-iV6aNhNL3bYOaQH7KkKKGBhZ5XTA/heO_kyZQ8Nw.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=eefdecb8246f6065a2eda03531f540b8",
      link: "https://vk.com/market/product/valyuta-na-2050vp-euru-227636417-10439339"
    },
    {
      id: 6,
      title: "ВАЛЮТА НА 1520VP EU/RU",
      price: 1299,
      image: "https://sun159-1.userapi.com/impg/IOt0R-4cKb13q0vD6ZrrJxrWOvxh5fp3mry5qg/4Tc-N-y7s_0.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=4d8c300988438ab733a97e4f7a4222aa",
      link: "https://vk.com/market/product/valyuta-na-1520vp-euru-227636417-10439324"
    },
    {
      id: 7,
      title: "ВАЛЮТА НА 1000VP EU/RU",
      price: 889,
      image: "https://sun159-2.userapi.com/impg/NXu50t-f1__x21-Xk51296JdUxFvDdLD0-8rwQ/KZmxunnqWfw.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=e2017e641fb93dc7920e0c28b6c69bce",
      link: "https://vk.com/market/product/valyuta-na-1000vp-euru-227636417-10439317"
    },
    {
      id: 8,
      title: "ВАЛЮТА НА 475VP EU/RU",
      price: 449,
      image: "https://sun159-2.userapi.com/impg/UX1T6LONsBouR4RMliiynILQqXl494L4-r6JPw/YRzgplc7gVE.jpg?size=0x180&crop=0,0,1,1&quality=95&sign=4012eabd672ccba72aa9a2228595b791",
      link: "https://vk.com/market/product/valyuta-na-475vp-euru-227636417-10439315"
    }
  ])

  // useEffect to handle page load
  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true)
    }

    // Check if the page is already loaded
    if (document.readyState === 'complete') {
      setIsPageLoaded(true)
    } else {
      window.addEventListener('load', handlePageLoad)
      // Clean up the event listener on unmount
      return () => {
        window.removeEventListener('load', handlePageLoad)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <section
        className="layout__section section"
        onMouseEnter={() => swiperRef.current?.autoplay?.stop()}
        onMouseLeave={() => swiperRef.current?.autoplay?.start()}
        style={{ width: '100%', height: '500px', userSelect: 'none' }}
      >
        <div className="section__navigation navigation" data-name="carousel">
          <div className="navigation__item navigation__item_prev">
            <svg className="navigation__ico">
              <use xlinkHref="/img/sprite.svg#navigationPrev"></use>
            </svg>
          </div>
          <div className="navigation__item navigation__item_next">
            <svg className="navigation__ico">
              <use xlinkHref="/img/sprite.svg#navigationNext"></use>
            </svg>
          </div>
        </div>
        <div className="section__main">
          <div className="container container_secondary">
            <Swiper
              className="layout__carousel carousel"
              modules={[EffectCoverflow, Navigation, Autoplay]}
              effect="coverflow"
              onSwiper={(swiper: SwiperType) => {
                // This callback runs when swiper is initialized
                swiperRef.current = swiper;
                console.log('Swiper instance:', swiper);
                // Once assigned, you can use swiperRef.current to access methods and properties
              }}
              centeredSlides={true}
              centerInsufficientSlides={true}
              loop={true}
              spaceBetween={105}
              autoplay={
                isPageLoaded
                  ? {
                      delay: 2500, // Delay in ms (adjusted to 2.5 seconds)
                      pauseOnMouseEnter: true,
                      disableOnInteraction: false // Keep autoplay running after interaction
                    }
                  : false // Disable autoplay until page is fully loaded
              }
              slidesPerView={4}
              speed={1500}
              allowTouchMove={false}
              touchRatio={1.5}
              coverflowEffect={{
                rotate: 0,
                stretch: 7,
                depth: 0,
                modifier: 10,
                slideShadows: false,
              }}
              navigation={{
                prevEl: "[data-name='carousel'] .navigation__item_prev",
                nextEl: "[data-name='carousel'] .navigation__item_next",
              }}
            >
              {items.map((item) => (
                <SwiperSlide key={item.id} className="carousel__item">
                  <picture className="carousel__media">
                    <img src={item.image} alt={item.title} loading="lazy" />
                  </picture>
                  <div className="carousel__main">
                    <span className="carousel__title">{item.title}</span>
                    <ul className="carousel__data data">
                      <li className="data__item">{item.price}₽</li>
                    </ul>
                  </div>
                  <div className="carousel__footer">
                    <a className="btn btn_secondary" href={item.link} target="_blank" rel="noopener noreferrer">
                      <span className="btn__text">Купить</span>
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Carousel
