function RefundPolicy() {
    return (
      <div className="layout__main layout__main_blog">
        <h2>Порядок возврата денежных средств</h2>

<p>При проведении онлайн-оплаты посредством платежных карт не допускается возврат наличными денежными средствами. Порядок возврата регулируется правилами международных платежных систем:</p>

<ul>
    <li>Потребитель вправе отказаться от товара в любое время до его передачи, после передачи товара отказ необходимо оформить в течение 14 дней;</li>
    <li>Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</li>
    <li>Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;</li>
    <li>При отказе от товара со стороны потребителя продавец должен вернуть ему денежную сумму, уплаченную потребителем, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования.</li>
</ul>

<h3>Возврат денежных средств на банковскую карту</h3>
<p>Для возврата денежных средств на банковскую карту необходимо заполнить <span >«Заявление о возврате денежных средств»</span>, которое высылается по требованию компанией на электронный адрес, и отправить его вместе с приложением копии документа, удостоверяющего личность, по адресу <a href="mailto:admin@csmboost.com">admin@csmboost.com</a>.</p>

<p>Возврат денежных средств будет осуществлен на банковскую карту в течение 5 рабочих дней со дня получения <span >«Заявления о возврате денежных средств»</span> Компанией.</p>

<h3>Возврат денежных средств по операциям, проведенным с ошибками</h3>
<p>Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с письменным заявлением и приложением копии документа, удостоверяющего личность, и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу <a href="mailto:admin@csmboost.com">admin@csmboost.com</a>.</p>

<p>Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников и выходных дней.</p>
</div>
    );
  }
  export default RefundPolicy;
  