import React, { useState, useEffect } from 'react'

const rankOrder = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Ascendant', 'Immortal', 'Radiant'];
const divisionOrder = ['I', 'II', 'III'];
const rrRanges = ['0-18', '19-36', '37-54', '55-72', '73-89'];

function getRankValue(rank: string): number {
  const [rankName, division, rr] = rank.split(' ');
  const rankIndex = rankOrder.indexOf(rankName);
  const divisionIndex = divisionOrder.indexOf(division);
  const rrValue = parseInt(rr.split('-')[1]);

  return (rankIndex * 1000000) + (divisionIndex * 10000) + rrValue;
}

interface RankSelectorProps {
  selectedRank: string;
  onRankChange: (newRank: string) => void;
  label: string;
  isEndRank: boolean;
  startRank?: string;
}

const rankImages = {
  'Iron': '/img/content/boost/boost__ico01.png',
  'Bronze': '/img/content/boost/boost__ico02.png',
  'Silver': '/img/content/boost/boost__ico03.png',
  'Gold': '/img/content/boost/boost__ico04.png',
  'Platinum': '/img/content/boost/boost__ico05.png',
  'Diamond': '/img/content/boost/boost__ico06.png',
  'Ascendant': '/img/content/boost/boost__ico07.png',
  'Immortal': '/img/content/boost/boost__ico08.png',
  'Radiant': '/img/content/boost/boost__ico09.png'
};

export function RankSelector({ selectedRank, onRankChange, label, isEndRank, startRank }: RankSelectorProps) {
  const [localRank, setLocalRank] = useState(selectedRank);
  const [selectedRR, setSelectedRR] = useState(selectedRank.split(' ')[2] || '0-18');

  useEffect(() => {
    setLocalRank(selectedRank);
    setSelectedRR(selectedRank.split(' ')[2] || '0-18');
  }, [selectedRank]);

  const isButtonHidden = (rank: string, division: string, rr: string): boolean => {
    if (!isEndRank || !startRank) return false;
    const fullRank = `${rank} ${division} ${rr}`;
    return false;
  };

  const handleRankSelect = (rank: string) => {
    let newRank = rank;
    if (rank !== 'Radiant') {
      newRank += ' I 0-18';
    }
      setLocalRank(newRank);
      setSelectedRR('0-18');
      onRankChange(newRank);
  };

  const handleDivisionSelect = (division: string) => {
    const [currentRank] = localRank.split(' ');
    const newRank = `${currentRank} ${division} ${selectedRR}`;
    if (!isEndRank || getRankValue(newRank) > getRankValue(startRank!)) {
      setLocalRank(newRank);
      onRankChange(newRank);
    }
  };

  const handleRRSelect = (rr: string) => {
    const [currentRank, currentDivision] = localRank.split(' ');
    const newRank = `${currentRank} ${currentDivision} ${rr}`;
    if (!isEndRank || getRankValue(newRank) > getRankValue(startRank!)) {
      setLocalRank(newRank);
      setSelectedRR(rr);
      onRankChange(newRank);
    }
  };

  const [currentRankName, currentDivision] = localRank.split(' ');

  return (
    <div className="calc__group">
      <div className="calc__header">
        <picture className="calc__picture">
          <img
            className=""
            src={rankImages[currentRankName as keyof typeof rankImages]}
            alt={`${localRank} ico`}
          />
        </picture>
        <div className="calc__title">
          <h6 className="calc__text">{label}</h6>
          <span className="calc__subtext">{localRank}</span>
        </div>
      </div>
      <div className="calc__main">
        <ul className="calc__list">
          {rankOrder.map((rank, index) => {
            const shouldHide = isButtonHidden(rank, 'I', '0-18');
            if (shouldHide) return null;
            return (
              <li
                key={rank}
                className={`calc__item ${currentRankName === rank ? 'calc__item_active' : ''}`}
                onClick={() => handleRankSelect(rank)}
              >
                <img className="calc__ico" src={`/img/content/boost/boost__ico0${index + 1}.png`} alt={rank} />
              </li>
            );
          })}
        </ul>
        {currentRankName !== 'Radiant' && (
          <ul className="calc__list calc__list_secondary">
            {divisionOrder.map(division => {
              const shouldHide = isButtonHidden(currentRankName, division, selectedRR);
              if (shouldHide) return null;
              return (
                <li
                  key={division}
                  className={`calc__item ${currentDivision === division ? 'calc__item_active' : ''}`}
                  onClick={() => handleDivisionSelect(division)}
                >
                  <span className="calc__text">{division}</span>
                </li>
              );
            })}
          </ul>
        )}
        <ul className="calc__list calc__list_secondary">
          {rrRanges.map(rr => {
            const shouldHide = isButtonHidden(currentRankName, currentDivision, rr);
            if (shouldHide) return null;
            return (
              <li
                key={rr}
                className={`calc__item ${selectedRR === rr ? 'calc__item_active' : ''}`}
                onClick={() => handleRRSelect(rr)}
              >
                <span className="calc__text">{rr} RR</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  )
}

export default RankSelector;

