import React from "react";
function Textarea(props: any) {
  return (
    <React.Fragment>
      <textarea
        id={props.id}
        className={`form__input form__input_textarea ${props.addClasses}`}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => props.textareaCallback(e.target.value)}
      />
    </React.Fragment>
  );
}
export default Textarea;
