import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../global/AuthContext";

interface HeaderProps {
  onOpenAuthModal: () => void
}

function Header({ onOpenAuthModal }: HeaderProps) {
  const { user, isLoggedIn, logout } = useAuth();

  let notifications = {
      link: "/dm",
      text: 0,
    },
    menu = [
      {
        link: "/",
        text: "Главная",
      },
      {
        link: "/boost",
        text: "Заказать буст",
      },
    ];

  const handleLogin = () => {
    window.location.href = "https://oauth.telegram.org/auth?bot_id=7105407484&origin=http://csmvalorant.com/&embed=1";
  };

  return (
    <React.Fragment>
      <header className="layout__header header">
        <picture className="header__picture">
          <img src="/img/header__background.jpg" alt="image description" />
        </picture>
        <div className="container">
          <div className="header__logo logo">
            <NavLink className="logo__link" to="/">
              <img src="/img/logo.png" alt="image description" />
            </NavLink>
          </div>

          <nav className="header__nav nav">
            <ul className="nav__list">
              {menu.map((menu: any, index: any) => (
                <li className="nav__item" key={index}>
                  <NavLink className="nav__link" to={menu.link}>
                    <span className="nav__title">{menu.text}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          <div className="header__languages languages"></div>

          {/* Show login button if not logged in */}
          {!isLoggedIn && (
            <a className="btn btn_tiny" onClick={onOpenAuthModal}>
              <svg className="btn__ico">
                <use xlinkHref="/img/sprite.svg#send"></use>
              </svg>
              <span className="btn__text">Вход</span>
            </a>
          )}

          {/* Show account info if logged in */}
          {isLoggedIn && (
          <div className="header__notifications notifications">
            <NavLink className="notifications__link" to={notifications.link}>
              <svg className="notifications__ico">
                <use xlinkHref="/img/sprite.svg#dialog"></use>
              </svg>
              {notifications.text !== 0 && (
                <span className="notifications__text">{notifications.text}</span>
              )}
            </NavLink>
          </div>
          )}

          {/* Show account info if logged in */}
          {isLoggedIn && (
            <div className="header__account account">
              <div className="account__header">
                <picture className="account__picture">
                  <img src={user?.avatar_url} alt="image description" />
                </picture>
                <span className="account__text" onClick={logout}>{user?.name || "Guest"}</span>
              </div>
            </div>
          )}
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
